import { render, staticRenderFns } from "./Journeyrecord.vue?vue&type=template&id=a1f1140c&scoped=true&"
import script from "./Journeyrecord.vue?vue&type=script&lang=js&"
export * from "./Journeyrecord.vue?vue&type=script&lang=js&"
import style0 from "./Journeyrecord.vue?vue&type=style&index=0&id=a1f1140c&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a1f1140c",
  null
  
)

export default component.exports