<template>
  <div class="Journeyrecord">
    <div class="head_img">
      <div class="img_title">
        <div class="img">
          <img v-if="demandinfo.projectImg" :src="demandinfo.projectImg" alt="" />
          <img v-else src="../../../assets/image/Group 3163.png" alt="" />
        </div>
        <div class="title">
          <p class="merchant_name">{{ demandinfo.projectName }}</p>
          <div class="merchant_type">
            <span style="font-size: 14px; color: #078bff"><i class="el-icon-alarm-clock"></i></span>
            <span style="font-size: 14px; padding-left: 10px"
              >时间：{{ demandinfo.startTime }}至{{ demandinfo.endTime }}</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="essential_information">
      <div class="form_essential">
        <el-form ref="form" :model="form" label-width="110px">
          <el-form-item label="精彩图片:" prop="">
            <div class="pictures">
              <div class="pictures_box" v-for="(item, index) in form.tripPhotos" :key="index">
                <div class="pictures_box_left">
                  <el-upload
                    class="avatar-uploader"
                    :class="{ hide: item.hideUpload }"
                    action=""
                    :on-preview="
                      (res) => {
                        return handlePictureCardPreviews(res, index)
                      }
                    "
                    :on-remove="
                      (res) => {
                        return handleRemove(res, index)
                      }
                    "
                    :http-request="
                      (res) => {
                        return uploadCategoryPics(res, index)
                      }
                    "
                    list-type="picture-card"
                    :file-list="item.filelist"
                    :limit="1"
                    accept=".jpg,.jpeg,.png,.tif,.gif"
                  >
                    <i class="el-icon-plus"></i>
                  </el-upload>
                  <el-dialog :visible.sync="dialogVisible" :append-to-body="true">
                    <img width="100%" :src="item.photoAddress" alt />
                  </el-dialog>
                </div>
                <div class="pictures_box_right">
                  <div>
                    <el-input v-model="item.photoName" placeholder="请输入图片名称" />
                    <div style="padding: 5px 0px"></div>
                    <el-input
                      v-model="item.photoDescribe"
                      type="textarea"
                      :autosize="{ minRows: 3, maxRows: 4 }"
                      placeholder="请输入图片描述"
                      maxlength="100"
                      show-word-limit
                    />
                  </div>
                  <div style="padding: 0px 20px">
                    <span v-if="index == 0" style="color: #4e93fb" @click="addgeneral"
                      ><i class="el-icon-circle-plus"></i
                    ></span>
                    <span v-if="index > 0" style="color: #ff7575" @click="delgeneral(index)"
                      ><i class="el-icon-delete"></i
                    ></span>
                  </div>
                </div>
              </div>
            </div>
          </el-form-item>
          <!-- <el-form-item label="精彩视频:" prop="commodityName">
            <div class="pictures">
              <div class="pictures_box" v-for="(item, index) in form.tripVideos" :key="index">
                <div class="pictures_box_left">
                  <img-big-upload
                    :imageShow.sync="item.videoAddress"
                    :addTitleShow="false"
                    @removeImg="removeImgDian"
                    @uploadOneImgShow="uploadOneImgShowDian"
                  />
                </div>
                <div class="pictures_box_right">
                  <div>
                    <el-input v-model="item.imgname" placeholder="请输入视频名称" />
                    <div style="padding: 5px 0px"></div>
                    <el-input
                      v-model="item.imgtxt"
                      type="textarea"
                      :autosize="{ minRows: 3, maxRows: 4 }"
                      placeholder="请输入视频描述"
                      maxlength="100"
                      show-word-limit
                    />
                  </div>
                  <div style="padding: 0px 20px">
                    <span v-if="index == 0" style="color: #4e93fb" @click="addgeneral"
                      ><i class="el-icon-circle-plus"></i
                    ></span>
                    <span v-if="index > 0" style="color: #ff7575" @click="delgeneral(index)"
                      ><i class="el-icon-delete"></i
                    ></span>
                  </div>
                </div>
              </div>
            </div>
          </el-form-item> -->
          <el-form-item label="附件:" prop="">
            <div class="pictures">
              <div class="trip_access" v-for="(item, index) in form.tripAccessories" :key="index">
                <div
                  class="name_form"
                  style="width: 700px; display: flex; justify-content: space-between"
                >
                  <el-input v-model="item.accessoryName" placeholder="" />
                  <el-upload
                    action
                    class="upload-demo"
                    ref="upload"
                    :show-file-list="false"
                    :before-upload="
                      (res) => {
                        return beforeUpload(res, index)
                      }
                    "
                    :http-request="
                      (res) => {
                        return upload(res, index)
                      }
                    "
                  >
                    <el-button slot="trigger" size="mini" type="danger">选取文件</el-button>
                  </el-upload>
                </div>
                <div style="padding: 0px 20px">
                  <span v-if="index == 0" style="color: #4e93fb" @click="addaccessory"
                    ><i class="el-icon-circle-plus"></i
                  ></span>
                  <span v-if="index > 0" style="color: #ff7575" @click="delaccessory(index)"
                    ><i class="el-icon-delete"></i
                  ></span>
                </div>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="成果展示:" prop="commodityName">
            <div class="pictures">
              <div style="display: flex" v-for="(item, index) in form.tripOutcomes" :key="index">
                <div style="margin-bottom: 10px">
                  <el-form-item label="是否有意向合作:" prop="" label-width="150px">
                    <el-radio-group v-model="item.isIntention">
                      <el-radio :label="'1'">是</el-radio>
                      <el-radio :label="'2'">否</el-radio>
                    </el-radio-group>
                  </el-form-item>
                  <el-form-item label="合作意向企业:" prop="" label-width="120px">
                    <div class="cooperation">
                      <el-input v-model="item.companyName" placeholder="请输入合作意向企业" />
                      <div class="proportion">
                        <el-input v-model="item.percentage" placeholder="请填写合作意向百分比" />
                        <span style="font-size: 12px; padding: 0px 5px">%</span>
                      </div>
                    </div>
                  </el-form-item>
                  <el-form-item label="合作意向描述:" prop="" label-width="120px">
                    <div class="txtree">
                      <el-input
                        v-model="item.intentionDescription"
                        type="textarea"
                        :autosize="{ minRows: 3, maxRows: 4 }"
                        placeholder="请输入内容"
                      />
                    </div>
                  </el-form-item>
                </div>
                <div style="padding: 0px 20px">
                  <span v-if="index == 0" style="color: #4e93fb" @click="addiption"
                    ><i class="el-icon-circle-plus"></i
                  ></span>
                  <span v-if="index > 0" style="color: #ff7575" @click="deliption(index)"
                    ><i class="el-icon-delete"></i
                  ></span>
                </div>
              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">保存</el-button>
        <el-button @click="cancel">取消</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { getdemand, addTripRecordert, updateTripRecorder, getTripRecorder } from '@/api/demand'
import { uploadAdmin } from '@/api/common'
export default {
  name: 'Journeyrecord',
  computed: {
    inspectDemandId() {
      return this.$route.query.inspectDemandId * 1
    },
    id() {
      return this.$route.query.id * 1
    }
  },
  data() {
    return {
      form: {
        tripAccessories: [
          {
            accessoryAddress: '',
            accessoryName: '',
            id: null,
            tripId: this.$route.query.id
          }
        ],
        tripId: this.$route.query.id,
        tripOutcomes: [
          {
            companyName: '',
            id: null,
            intentionDescription: '',
            isIntention: '',
            percentage: 0,
            tripId: this.$route.query.id
          }
        ],
        tripPhotos: [
          {
            id: null,
            photoAddress: '',
            photoDescribe: '',
            photoName: '',
            tripId: this.$route.query.id,
            hideUpload: false
          }
        ]
      },
      demandinfo: {},
      dialogVisible: false,

      fileList: []
    }
  },
  created() {
    this.getdemand()
    if (this.id) {
      this.getTripRecorder()
    }
  },
  methods: {
    //获取信息
    async getdemand() {
      const res = await getdemand(this.inspectDemandId)
      if (res.data.resultCode == 200) {
        this.demandinfo = res.data.data
      }
    },
    //获取行程记录
    async getTripRecorder() {
      const res = await getTripRecorder(this.$route.query.id)
      if (res.data.resultCode == 200) {
        this.form = res.data.data
        if (this.form.tripAccessories.length == 0) {
          this.form.tripAccessories = [
            {
              accessoryAddress: '',
              accessoryName: '',
              id: null,
              tripId: this.$route.query.id
            }
          ]
        }
        if (this.form.tripOutcomes.length == 0) {
          this.form.tripOutcomes = [
            {
              companyName: '',
              id: null,
              intentionDescription: '',
              isIntention: '',
              percentage: 0,
              tripId: this.$route.query.id
            }
          ]
        }
        if (this.form.tripPhotos.length == 0) {
          this.form.tripPhotos = [
            {
              id: null,
              photoAddress: '',
              photoDescribe: '',
              photoName: '',
              tripId: this.$route.query.id,
              hideUpload: false
            }
          ]
        } else {
          this.form.tripPhotos.forEach((o) => {
            if (o.photoAddress) {
              o.filelist = [{ name: o.photoAddress, url: o.photoAddress }]
              o.hideUpload = true
            }
          })
        }

        this.form.tripId = this.$route.query.id
      }
    },
    //新增精彩图片
    addgeneral() {
      this.form.tripPhotos.push({
        id: null,
        photoAddress: '',
        photoDescribe: '',
        photoName: '',
        tripId: this.$route.query.id,
        hideUpload: false
      })
    },
    handlePictureCardPreviews(file, index) {
      this.form.tripPhotos[index].photoAddress = file.url
      this.dialogVisible = true
    },
    //上传图片
    async uploadCategoryPics(params, index) {
      const form = new FormData()
      form.append('file', params.file)

      const res = await uploadAdmin(form)
      if (res.data.resultCode === 200) {
        this.form.tripPhotos[index].photoAddress = res.data.data
        this.form.tripPhotos[index].hideUpload = true
      }
    },
    handleRemove(file, index) {
      this.form.tripPhotos[index].photoAddress = ''
      this.form.tripPhotos[index].hideUpload = false
    },
    //删除精彩图片
    delgeneral(index) {
      this.form.tripPhotos.splice(index, 1)
    },
    //上传pdf
    async beforeUpload(file, index) {
      this.file = file
      this.form.tripAccessories[index].accessoryName = file.name

      const extension = file.name.split('.').splice(-1) == 'pdf'
      if (!extension) {
        this.$message.warning('上传模板只能是pdf格式')
        return
      }
      let reader = new FileReader()
      reader.readAsDataURL(file)

      let that = this
      reader.onload = function () {
        that.fileData = reader.result
      }
      return false
    },
    //上传
    async upload(params, index) {
      const form = new FormData()
      form.append('file', params.file)

      const res = await uploadAdmin(form)
      if (res.data.resultCode === 200) {
        this.form.tripAccessories[index].accessoryAddress = res.data.data
      }
    },

    //add附件
    addaccessory() {
      this.form.tripAccessories.push({
        companyName: '',
        id: null,
        intentionDescription: '',
        isIntention: '',
        percentage: 0,
        tripId: this.$route.query.id
      })
    },
    delaccessory(index) {
      this.form.tripAccessories.splice(index, 1)
    },
    deliption(index) {
      this.form.tripOutcomes.splice(index, 1)
    },

    addiption() {
      this.form.tripOutcomes.push({
        id: null,
        photoAddress: '',
        photoDescribe: '',
        photoName: '',
        tripId: this.$route.query.id
      })
    },
    /** 提交按钮 */
    submitForm() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          if (this.id == null) {
            addTripRecordert(this.form).then((response) => {
              this.$message.success('保存成功')
              this.$router.go(-1)
            })
          } else {
            updateTripRecorder(this.form).then((response) => {
              this.$message.success('保存成功')
              this.$router.go(-1)
            })
          }
        }
      })
    },
    //取消
    cancel() {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang="less" scoped>
.hide ::v-deep .el-upload--picture-card {
  display: none;
}
::v-deep .el-upload-list__item {
  transition: none !important;
}
.avatar-uploader .el-upload {
  border: none;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

::v-deep .el-upload--picture-card {
  background-color: #fbfdff;
  border: 1px dashed #c0ccda;
  border-radius: 6px;
  box-sizing: border-box;
  width: 124px;
  height: 124px;
  cursor: pointer;
  line-height: 124px;
  vertical-align: top;
}
::v-deep .el-upload-list--picture-card .el-upload-list__item {
  width: 124px;
  height: 124px;
  text-align: center;
}
::v-deep .pictures .el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 41px;
  line-height: 41px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 596px;
}
::v-deep .cooperation .el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 41px;
  line-height: 41px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 386px;
}
::v-deep .proportion .el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 41px;
  line-height: 41px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 176px;
}
::v-deep .el-textarea {
  position: relative;
  display: inline-block;
  width: 596px;
  vertical-align: bottom;
  font-size: 14px;
}
::v-deep .el-form-item__content {
  line-height: 40px;
  position: relative;
  font-size: 19px;
}
.Journeyrecord {
  .dialog-footer {
    display: flex;
    justify-content: center;
  }
  .head_img {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background: #ffffff;

    .img_title {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 10px 10px 5px 10px;

      img {
        width: 68px;
        height: 68px;
      }

      .title {
        margin: 0px 0px 0px 10px;

        .merchant_name {
          font-weight: 550;
          font-size: 18px;
          color: #1f2d3d;
          margin: 0px;
          position: relative;
          top: -15px;
        }

        .merchant_type {
          position: relative;
          top: 10px;
        }
      }
    }
  }
  .essential_information {
    margin-top: 20px;
    padding: 40px 0px;
    background: #ffffff;
    .form_essential {
      .pictures {
        width: 817px;
        border: 1px dashed #c4c4c4;
        padding: 20px;
        .cooperation {
          display: flex;
          padding-bottom: 10px;
          .proportion {
            display: flex;
            position: relative;
            left: -110px;
          }
        }
        .trip_access {
          display: flex;
          margin-bottom: 10px;
        }
        .pictures_box {
          display: flex;
          padding-bottom: 20px;
          .pictures_box_left {
            padding-right: 20px;
          }
          .pictures_box_right {
            display: flex;
          }
        }
      }
    }
  }
}
</style>
